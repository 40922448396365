import Axios from 'axios';
import React, { ReactElement, useState } from 'react';

import { AppConfig } from '../../config/app.config';
import CenterOfBoxDataTable from './CenterOfBoxDataTable';

export default function CenterOfBox(): ReactElement {
  const [csv, setCSV] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [sat, setSat] = useState<{ name: string; path?: string; override?: boolean }>();
  const [error, setError] = useState<Error>();

  const satellites = [
    {
      name: 'Anik',
      satellites: [
        { name: 'Anik F1R', path: 'AnikF1R.C.csv' },
        {
          name: 'Anik F2 (COB predicts are only detailed for a 24 - 48 hour period)',
          path: 'AnikF2.C.csv',
        },
        { name: 'Anik F3', path: 'AnikF3.C.csv' },
        { name: 'Anik F4', path: 'AnikF4.C.csv' },
        { name: 'Anik G1', path: 'AnikG1.C.csv' },
      ],
    },
    {
      name: 'Nimiq',
      satellites: [
        { name: 'Nimiq 4', path: 'NimiqN4.C.csv' },
        { name: 'Nimiq 5', path: 'NimiqN5.C.csv' },
        { name: 'Nimiq 6', path: 'NimiqN6.C.csv' },
      ],
    },
    {
      name: 'Telstar',
      satellites: [
        { name: 'Telstar 11N', path: 'Telstar11N.C.csv' },
        { name: 'Telstar 12', path: 'Telstar12.C.csv' },
        { name: 'Telstar 12V', path: 'Telstar12V.C.csv' },
        { name: 'Telstar 14R', path: 'Telstar14R.C.csv' },
        { name: 'Telstar 18V', path: 'Telstar18V.C.csv' },
        { name: 'Telstar 19V', path: 'Telstar19V.C.csv' },
      ],
    },
  ];

  function selectSatellite(): void {
    setError(null);
    setCSV(null);

    if (sat) {
      if (sat.override === true) {
        setError({
          name: 'Technical Support Override',
          message: `Please contact <a class="underline" href="https://www.telesat.com/contact-us/#support">Telesat Technical Support</a>.`,
        });
        return;
      }

      Axios.get(`${AppConfig.data.root}/${sat.path}`)
        .then(res => {
          setCSV(res.data);
          setTitle(sat.name);
        })
        .catch(err => {
          setError(err);
        });
    }
  }

  return (
    <div className="flex flex-col p-6 space-y-6" style={{ maxWidth: '100vw' }}>
      <div className="flex flex-wrap">
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select
          className="form-select flex-grow w-40"
          onChange={e => e.target.value && setSat(JSON.parse(e.target.value))}
        >
          <option value="" selected={true} disabled={true} hidden={true}>
            Select a satellite
          </option>
          {satellites.map(group => (
            <optgroup key={group.name} label={group.name}>
              {group.satellites.map(sat => (
                <option key={sat.path} value={JSON.stringify(sat)}>
                  {sat.name}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
        <button onClick={() => selectSatellite()} className="button md:mt-0 mt-3 ml-2">
          View
        </button>
      </div>
      <CenterOfBoxDataTable title={title} csv={csv} error={error?.message} />
    </div>
  );
}
